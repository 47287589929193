import { ReverseGeocodeAddress } from "@modules/location/types/ReverseGeocodeAddress";
import { createContext } from "react";

export type LocationModalContextState = {
  handleOpenLocationModal(): void;
  handleCloseLocationModal(): void;
  handleAddressChange(address: ReverseGeocodeAddress | null): void;
  userLocation?: ReverseGeocodeAddress | null;
  setUserLocation(address: ReverseGeocodeAddress | null): void;
};

const LocationModalContext = createContext<LocationModalContextState>(
  {} as LocationModalContextState
);

export default LocationModalContext;
