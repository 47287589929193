import api from "@modules/api/api";
import { useMutation } from "@tanstack/react-query";

const unsubscribeSavedSearches = async (token: string): Promise<Response> => {
  return api.rawPost(
    `api/customers/me/vehicle-searches/unsubscribe?token=${token}`
  );
};

export default function useUnsubscribeSavedSearchesMutation() {
  return useMutation({ mutationFn: unsubscribeSavedSearches });
}
