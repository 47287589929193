import Button from "@common/components/Button";
import {
  ArrowRightIcon,
  SortDownIcon,
  SortUpIcon,
} from "@common/components/icons";
import { gtmPushData } from "@common/helpers/gtm";
import LocationButtonDetailed from "@modules/location/components/LocationButtonDetailed";
import { VehicleFiltersData } from "@modules/vehicle/types/vehicleFilter";
import { User } from "@sentry/nextjs";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Event as HotjarEvent, hotjar } from "@modules/core/hotjar/types";
import { LoginModalMode, loginMode } from "@modules/user/types/LoginModalMode";
import GetNavItems from "./getNavItems";
import { DynamicShopBy } from "./shop-by";

type Props = {
  user: User | undefined;
  vehicleFilters: VehicleFiltersData | null;
  showShopByDisclosure: boolean;
  isFiltersLoading: boolean;
  setShowShopByDisclosure: Dispatch<SetStateAction<boolean>>;
  setShowLoginModal: Dispatch<SetStateAction<boolean>>;
  setLoginModalMode: Dispatch<SetStateAction<LoginModalMode>>;
  onClose: () => void;
};

const getLoginSignUpBannerStyle = () => {
  let className = {
    containerDiv: "",
    wordingDiv: "",
  };

  const lgMobileLandscapeBreakpoint = 412;

  if (typeof window !== "undefined") {
    if (window.innerHeight <= lgMobileLandscapeBreakpoint) {
      className = {
        containerDiv: "p-2 flex justify-center",
        wordingDiv: "hidden",
      };
    } else {
      className = {
        containerDiv: "p-5",
        wordingDiv: "block",
      };
    }
  }

  return className;
};

const SideNavBar = ({
  user,
  vehicleFilters,
  showShopByDisclosure,
  isFiltersLoading,
  setShowShopByDisclosure,
  setShowLoginModal,
  setLoginModalMode,
  onClose,
}: Props) => {
  const { t } = useTranslation(["common"]);
  const shopByBtnRef = useRef(null);
  const router = useRouter();

  const [loginSignUpBannerStyle, setLoginSignUpBannerStyle] = useState<{
    containerDiv: string;
    wordingDiv: string;
  }>(getLoginSignUpBannerStyle());

  const setInitialStyle = () => {
    setLoginSignUpBannerStyle(getLoginSignUpBannerStyle);
  };

  useEffect(() => {
    window.onresize = setInitialStyle;
    window.onload = setInitialStyle;
  }, []);

  const onMobileNavItemClick = (slug: string): void => {
    onClose();
    router.push(`${slug}`);
  };

  return (
    <>
      <div
        className="flex flex-col w-[17.5rem] h-full overflow-y-scroll"
        id="mobile-nav-items"
      >
        <div className="grid grid-cols-1 [&>*]:body-3 pb-3 border-t border-gray-50">
          <div className="border-b border-gray-50">
            <div
              className=" active:bg-primary-light hover:bg-background-light-300"
              onClick={() => setShowShopByDisclosure(!showShopByDisclosure)}
              onKeyDown={() => setShowShopByDisclosure(!showShopByDisclosure)}
              role="button"
              tabIndex={0}
            >
              <Button
                fill="link"
                buttonStyle="borderless"
                spacing="tight-hug-icon"
                className="pl-3 !text-black"
                rightIcon={
                  showShopByDisclosure ? (
                    <SortUpIcon
                      className="text-primary-bold"
                      fontSize="1.5rem"
                    />
                  ) : (
                    <SortDownIcon
                      className="text-primary-bold"
                      fontSize="1.5rem"
                    />
                  )
                }
                ref={shopByBtnRef}
              >
                {t("common:shop")}
              </Button>
            </div>

            {!isFiltersLoading && showShopByDisclosure && (
              <div className="bg-gray-25 p-4">
                <DynamicShopBy
                  makes={vehicleFilters?.makesAndModels.flatMap((m) => m.name)}
                  bodyStyles={vehicleFilters?.bodyStyles}
                  layoutStyle="mobileNav"
                  onClose={onClose}
                />
              </div>
            )}
          </div>

          {GetNavItems("Side").map((link, index) => {
            return (
              <span
                key={link.url}
                className="border-b border-gray-50 active:bg-primary-light hover:bg-background-light-300"
              >
                <div
                  key={link.title}
                  className="px-3 py-4"
                  onClick={() => onMobileNavItemClick(link.url)}
                  onKeyDown={() => onMobileNavItemClick(link.url)}
                  role="button"
                  tabIndex={index}
                >
                  <Link href={link.url}>{link.title}</Link>
                </div>
              </span>
            );
          })}
          <div className="grid grid-cols-1 [&>*]:caption-2">
            {GetNavItems("SideSubNav").map((link, index) => {
              return (
                <span
                  key={link.url}
                  className="active:bg-primary-light hover:bg-background-light-300"
                >
                  <div
                    key={link.title}
                    className="px-3 py-4"
                    onClick={() => onMobileNavItemClick(link.url)}
                    onKeyDown={() => onMobileNavItemClick(link.url)}
                    role="button"
                    tabIndex={index}
                  >
                    <Link href={link.url}>{link.title}</Link>
                  </div>
                </span>
              );
            })}
          </div>
        </div>
        <div className="px-3 mb-3">
          <LocationButtonDetailed navLink />
        </div>
      </div>
      {!user ||
        (!user.isLoggedIn && (
          <div
            className={`bg-cyan-100 w-full border-none ${loginSignUpBannerStyle.containerDiv}`}
          >
            <div
              className={`body-3 w-56 pb-5 ${loginSignUpBannerStyle.wordingDiv}`}
            >
              {t("common:mobile_nav_login_heading")}
            </div>
            <Button
              fill="outline"
              buttonStyle="transparent"
              spacing="tight-hug"
              size="small"
              onClick={() => {
                gtmPushData({
                  event: "Navigation",
                  element: "Authenticate",
                });
                hotjar.event(HotjarEvent.UserLogin);
                setLoginModalMode(loginMode);
                setShowLoginModal(true);
              }}
              rightIcon={<ArrowRightIcon />}
            >
              {t("common:log_in_or_sign_up")}
            </Button>
          </div>
        ))}
    </>
  );
};

export default SideNavBar;
