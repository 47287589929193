import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect, useState } from "react";

type Props = {
  pathsToHideScript: RegExp[];
  scriptSrc: string;
  scriptId?: string;
  async?: boolean;
  setElementStyles: (isShown: boolean) => void;
  strategy?: "afterInteractive" | "lazyOnload" | "beforeInteractive" | "worker";
};

const PageScriptValidator = ({
  pathsToHideScript,
  scriptSrc,
  scriptId = "",
  async = false,
  setElementStyles,
  strategy = "afterInteractive",
}: Props) => {
  const router = useRouter();
  const [isValidPage, setIsValidPage] = useState<boolean>(false);

  useEffect(() => {
    if (!router.isReady) return undefined;

    const isValid = !pathsToHideScript.some((path) => path.test(router.asPath));

    setIsValidPage(isValid);

    let observer: MutationObserver;
    if (isValid) {
      observer = new MutationObserver(() => {
        setElementStyles(isValid);
      });

      const targetNode = document.documentElement || document.body;
      observer.observe(targetNode, {
        childList: true,
        subtree: true,
      });
    }

    setElementStyles(isValid);

    return () => {
      observer?.disconnect();
    };
  }, [
    isValidPage,
    router.asPath,
    router.isReady,
    setElementStyles,
    pathsToHideScript,
  ]);

  return isValidPage ? (
    <Script src={scriptSrc} id={scriptId} async={async} strategy={strategy} />
  ) : null;
};

export default PageScriptValidator;
