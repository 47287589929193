import useOnClickOutside from "@common/hooks/useOnClickOutside";
import { Transition } from "@headlessui/react";
import { ReactNode, RefObject, useEffect, useRef, useState } from "react";

type Props = {
  children: ReactNode;
  showSideNav: boolean;
  navBtnRef: RefObject<HTMLButtonElement>;
  sideNavBtnRefStickySearch: RefObject<HTMLButtonElement>;
  onClose: () => void;
};

const SideNavBarModal = ({
  children,
  showSideNav,
  navBtnRef,
  sideNavBtnRefStickySearch,
  onClose,
}: Props) => {
  // Position of side nav is dynamic based on scroll position between the top of page and when the global search bar sticks.
  const pageAtTopOffset = 121;
  const pageAtSearchbarStickyOffset = 58;
  const [sideNavDifOffSetY, setSideNavDifOffSetY] = useState(pageAtTopOffset);

  useEffect(() => {
    const onScroll = () =>
      setSideNavDifOffSetY(
        Math.max(pageAtTopOffset - window.scrollY, pageAtSearchbarStickyOffset)
      );

    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const popUpRef = useRef(null);

  useOnClickOutside([popUpRef, navBtnRef, sideNavBtnRefStickySearch], () => {
    onClose();
  });

  return (
    <div className="2md:hidden">
      {showSideNav && (
        <>
          <div
            style={
              {
                "--sideNavDifOffSetY": `${sideNavDifOffSetY}px`,
              } as React.CSSProperties
            }
            className="fixed top-[var(--sideNavDifOffSetY)] w-screen h-screen bg-black z-30 opacity-30"
          />
          <div id="NavSideBar" className="fixed flex z-50" ref={popUpRef}>
            <div
              style={
                {
                  "--sideNavDifOffSetY": `${sideNavDifOffSetY}px`,
                } as React.CSSProperties
              }
              className="fixed flex inset-y-0 top-[var(--sideNavDifOffSetY)] right-0 max-w-full"
            >
              <Transition
                show={showSideNav}
                enter="transition ease-in-out duration-150 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-150 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="2md:hidden flex flex-col bg-white w-[17.5rem] h-full overflow-x-hidden">
                  {children}
                </div>
              </Transition>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SideNavBarModal;
