import useOnClickOutside from "@common/hooks/useOnClickOutside";
import { Transition } from "@headlessui/react";
import { VehicleBodyStyle } from "@modules/vehicle/types/Vehicle";
import { RefObject, useRef } from "react";
import { DynamicShopBy } from ".";

type Props = {
  makes: string[] | undefined;
  bodyStyles: VehicleBodyStyle[] | undefined;
  showModal: boolean;
  isLoading: boolean;
  shopBtnRef: RefObject<HTMLButtonElement>;
  onClose: () => void;
};

const ShopByModal = ({
  makes,
  bodyStyles,
  showModal,
  isLoading,
  shopBtnRef,
  onClose,
}: Props) => {
  const popUpRef = useRef(null);

  useOnClickOutside([popUpRef, shopBtnRef], () => {
    onClose();
  });

  return (
    <div className="hidden md:block">
      {showModal && (
        <>
          <div className="fixed top-[9.5rem] w-screen h-screen bg-black z-30 opacity-30" />
          <div id="ShopByModal" className="fixed flex z-40">
            <div className="fixed flex top-[9.5rem] max-w-full inset-y-0 overflow-y-hidden overflow-x-hidden">
              <Transition
                show={showModal}
                enter="transition ease-in-out duration-150 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-150 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                {!isLoading && (
                  <div
                    className="bg-gray-25 px-32 xl:px-52 2xl:px-96 py-8 w-screen shadow-elevation-02"
                    ref={popUpRef}
                  >
                    <DynamicShopBy
                      bodyStyles={bodyStyles}
                      makes={makes}
                      layoutStyle="mainNav"
                      onClose={onClose}
                    />
                  </div>
                )}
              </Transition>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ShopByModal;
