import { gtmPushData } from "@common/helpers/gtm";
import { FavouriteOnIcon } from "@common/components/icons";
import classNames from "classnames";
import Link, { LinkProps } from "next/link";
import { useEffect, useState } from "react";

type Props = {
  count?: number;
  className?: string;
} & LinkProps;

const FavouritesLink = ({ className = "", count = 0, ...props }: Props) => {
  const [animate, setAnimate] = useState<boolean>(false);
  const [favouritesCount, setFavouritesCount] = useState<number>(count);

  useEffect(() => {
    if (count === favouritesCount) {
      return;
    }

    setAnimate(true);
    setFavouritesCount(count);
    setTimeout(() => setAnimate(false), 400);
  }, [count, favouritesCount, setAnimate]);

  const handleLinkClick = () => {
    gtmPushData({
      event: "Navigation",
      element: "Favourites",
    });
  };

  return (
    <Link
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      role="link"
      tabIndex={0}
      className={classNames(
        "group inline-flex items-center p-1 border border-transparent rounded-full focus:outline-none focus-visible:shadow-elevation-03",
        className
      )}
      onClick={handleLinkClick}
      onKeyUp={handleLinkClick}
      title="Click to navigate to saved vehicles"
    >
      <span className="inline-block relative">
        {animate && (
          <FavouriteOnIcon className="absolute text-secondary-salmon w-7 h-7 animate-ping" />
        )}
        <FavouriteOnIcon className="text-secondary-salmon w-7 h-7 group-hover:text-secondary-salmon-600" />
        {count > 0 && (
          <span className="absolute p-[2px] -bottom-[5px] -right-[3px] block bg-white rounded-full">
            <div className="display h-[12px] w-[12px] rounded-full text-[11px] font-semibold leading-none text-center">
              {count}
            </div>
          </span>
        )}
      </span>
    </Link>
  );
};

export default FavouritesLink;
