import Avatar, { AvatarSize } from "@common/components/Avatar";
import apiImageLoader from "@common/helpers/imageLoaders/apiImageLoader";
import { ReactNode } from "react";

type Props = {
  customer: {
    id: string;
    name?: string;
    firstName?: string;
    lastName?: string;
    hasProfilePhoto: boolean;
  };
  isCurrentUser?: boolean;
  size?: AvatarSize;
  rounded?: boolean;
  isProfileAvatar?: boolean;
  noInitials?: boolean;
  children?: ReactNode;
};

const CustomerAvatar = ({
  customer,
  isCurrentUser = false,
  size,
  rounded = true,
  isProfileAvatar,
  noInitials,
  children,
}: Props) => {
  let avatarImageUrl: string | undefined;

  if (customer.hasProfilePhoto) {
    if (isCurrentUser) {
      avatarImageUrl = `${process.env.NEXT_PUBLIC_API}/api/customers/me/photo?v=${customer.id}`;
    } else {
      avatarImageUrl = `${process.env.NEXT_PUBLIC_API}/api/users/customers/${customer.id}/photo`;
    }
  }

  return (
    <Avatar
      imageUrl={avatarImageUrl}
      imageLoader={apiImageLoader}
      text={customer.name || `${customer.firstName} ${customer.lastName}`}
      size={size}
      rounded={rounded}
      isProfileAvatar={isProfileAvatar}
      noInitials={noInitials}
    >
      {children}
    </Avatar>
  );
};

export default CustomerAvatar;
