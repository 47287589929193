import Button from "@common/components/Button";
import { MapPinIcon } from "@common/components/icons";
import { useTranslation } from "next-i18next";
import LocationModalContext from "@modules/location/LocationModalContext";
import { useContext } from "react";

type Props = {
  navLink?: boolean;
};

const LocationButtonDetailed = ({ navLink = false }: Props) => {
  const { t } = useTranslation(["common"]);
  const { handleOpenLocationModal, userLocation } =
    useContext(LocationModalContext);

  if (navLink) {
    return (
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          handleOpenLocationModal();
        }}
      >
        <div className="w-full text-primary-bold flex flex-row items-center caption-1 focus:outline-none focus-visible:shadow-elevation-03">
          <div className="pr-1 pb-1">
            <MapPinIcon fontSize="1rem" />
          </div>
          <div>
            {userLocation
              ? `${userLocation?.city}, ${userLocation?.provinceAbbr}`
              : t("common:set_location")}
          </div>
        </div>
      </a>
    );
  }
  return (
    <Button
      buttonStyle="white"
      size="small"
      leftIcon={<MapPinIcon fontSize="1.2rem" />}
      iconClassName="text-primary-bold"
      className="focus:outline-none focus-visible:shadow-elevation-01"
      onClick={handleOpenLocationModal}
    >
      {userLocation
        ? `${userLocation?.city}, ${userLocation?.provinceAbbr}`
        : t("common:set_location")}
    </Button>
  );
};

export default LocationButtonDetailed;
