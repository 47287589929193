import CircularButton, {
  Props as CircularButtonProps,
} from "@common/components/CircularButton";
import { FilterIcon } from "@common/components/icons";

type Props = {
  count?: number;
} & CircularButtonProps;

const VehiclesFilterButton = ({ count = 0, ...props }: Props) => {
  return (
    <CircularButton
      className="border-gray-50 focus:outline-none focus:bg-primary-bright-50/30"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <span className="inline-block relative p-1">
        <FilterIcon className="text-primary-bold w-6 h-6 group-hover:text-secondary-salmon-600" />
        {count > 0 && (
          <span className="absolute p-[4px] -bottom-[4px] -right-2 block bg-secondary-salmon text-white rounded-full">
            <div className="h-[12px] w-[12px] rounded-full text-sm font-semibold leading-none text-center">
              {count}
            </div>
          </span>
        )}
      </span>
    </CircularButton>
  );
};

export default VehiclesFilterButton;
