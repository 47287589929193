import classNames from "classnames";
import Image, { ImageLoader } from "next/image";
import { ReactNode } from "react";

export type AvatarSize =
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
  | "8xl"
  | "12xl"
  | "16xl";

type Props = {
  imageUrl?: string;
  imageLoader?: ImageLoader;
  text?: string;
  size?: AvatarSize;
  rounded?: boolean;
  objectCover?: boolean;
  isProfileAvatar?: boolean;
  noInitials?: boolean;
  children?: ReactNode;
};

const sizeClass = {
  xs: "h-6 w-6 text-xs",
  sm: "h-8 w-8 text-sm",
  md: "h-10 w-10 text-lg",
  lg: "h-12 w-12 text-xl",
  xl: "h-14 w-14 text-2xl",
  "2xl": "h-16 w-16 text-3xl",
  "8xl": "h-28 w-28 text-6xl",
  "12xl": "h-36 w-36 text-6xl",
  "16xl": "h-48 w-48 text-9xl",
};

const getColor = (text: string) => {
  const stringUniqueHash = text.split("").reduce((acc, char) => {
    // eslint-disable-next-line no-bitwise
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  const colors = [
    "#00CFEB",
    "#293EDD",
    "#FB637E",
    "#FFC7F4",
    "#8A2A2B",
    "#8A2A2B",
    "#003A40",
    "#8F96AE",
    "#454D63",
    "#4EB782",
    "#FFA34D",
    "#E57184",
    "#007A3D",
    "#F57600",
    "#D3314C",
  ];

  return colors[Math.abs(stringUniqueHash % colors.length)];
};

const Avatar = ({
  imageUrl,
  imageLoader,
  text,
  size,
  rounded,
  objectCover,
  isProfileAvatar,
  noInitials,
  children,
}: Props) => {
  const initials = text
    ?.split(" ")
    .map((s) => s[0])
    .slice(0, 2)
    .join("");

  let aliasColors = isProfileAvatar
    ? { bgColor: "#293EDD", textColor: "text-primary-light" }
    : { bgColor: undefined, textColor: "text-white" };

  if (noInitials) {
    aliasColors = { bgColor: "#8F96AE", textColor: "text-gray-300" };
  }

  return (
    <span className="inline-block relative">
      {!imageUrl && (
        <span
          style={{
            backgroundColor: aliasColors?.bgColor || getColor(text || ""),
          }}
          className={classNames(
            "inline-flex items-center justify-center",
            sizeClass[size || "md"],
            { "rounded-full": rounded }
          )}
        >
          <p className={`font-medium ${aliasColors.textColor}`}>{initials}</p>
        </span>
      )}

      {imageUrl && (
        <div className={classNames("relative", sizeClass[size || "md"])}>
          {/* eslint-disable-next-line react/forbid-elements */}
          <Image
            className={classNames(
              { "rounded-full": rounded },
              { "object-cover": objectCover }
            )}
            src={imageUrl}
            alt={`Profile picture for ${text}`}
            fill
            loader={imageLoader || undefined}
            unoptimized
          />
        </div>
      )}

      <div className="top-1.5">{children}</div>
    </span>
  );
};

export default Avatar;
