import { User } from "@modules/user/types/user";
import useGetVehicleFilterData from "@modules/vehicle/hooks/useVehicleFilterData";
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { gtmPushData } from "@common/helpers/gtm";
import { LoginModalMode } from "@modules/user/types/LoginModalMode";
import FavouritesLink from "../FavouritesLink";
import TopNavBar from "./TopNavBar";
import SideNavBarModal from "./SideNavBarModal";
import SideNavBar from "./SideNavBar";

type Props = {
  user: User | undefined;
  showSideNav: boolean;
  sideNavBtnRefStickySearch: RefObject<HTMLButtonElement>;
  setShowSideNav: Dispatch<SetStateAction<boolean>>;
  setShowLoginModal: Dispatch<SetStateAction<boolean>>;
  setLoginModalMode: Dispatch<SetStateAction<LoginModalMode>>;
};

const NavBar = ({
  user,
  showSideNav,
  sideNavBtnRefStickySearch,
  setShowSideNav,
  setShowLoginModal,
  setLoginModalMode,
}: Props) => {
  const [showShopByDisclosure, setShowShopByDisclosure] =
    useState<boolean>(false);
  const [showShopByModal, setShowShopByModal] = useState<boolean>(false);

  const sideNavBtnRef = useRef(null);

  const { vehicleFilters: vehicleFilterData, isLoading: isFiltersLoading } =
    useGetVehicleFilterData();

  /* Locks the screen whenever our custom shop-by or mobile-nav modals are open.
     Also prevents body from shifting when scroll bar is hidden 
     (scroll-disabled) using same technique as headless ui */
  useEffect(() => {
    const modalOpen = showShopByModal || showSideNav;
    const scrollBarPlaceHolder =
      window.innerWidth - document.documentElement.clientWidth;

    const root = document.documentElement;
    if (modalOpen) {
      root.style.overflow = "hidden";
      root.style.paddingRight = `${scrollBarPlaceHolder}px`;
    } else {
      root.style.removeProperty("overflow");
      root.style.removeProperty("padding-right");
    }
  }, [showShopByModal, showSideNav]);

  const favouritesLink = () => {
    return (
      <FavouritesLink
        href="/profile/saved-vehicles"
        className="self-center"
        count={user?.favouriteVehicles?.length || 0}
      />
    );
  };

  const handleMobileMenuClick = () => {
    gtmPushData({
      event: "Navigation",
      element: showSideNav ? "Hamburger" : "Close",
    });
    setShowSideNav(!showSideNav);
    setShowShopByDisclosure(false);
  };

  const onClose = () => {
    setShowSideNav(false);
    setShowShopByModal(false);
    setShowShopByDisclosure(false);
  };

  return (
    <>
      <TopNavBar
        favouritesLink={favouritesLink()}
        showShopByModal={showShopByModal}
        setShowShopByModal={setShowShopByModal}
        sideNavBtnRef={sideNavBtnRef}
        showSideNav={showSideNav}
        vehicleFilters={vehicleFilterData}
        isFiltersLoading={isFiltersLoading}
        onMobileMenuClick={handleMobileMenuClick}
        onClose={onClose}
      />
      <SideNavBarModal
        showSideNav={showSideNav}
        navBtnRef={sideNavBtnRef}
        sideNavBtnRefStickySearch={sideNavBtnRefStickySearch}
        onClose={onClose}
      >
        <SideNavBar
          user={user}
          vehicleFilters={vehicleFilterData}
          showShopByDisclosure={showShopByDisclosure}
          isFiltersLoading={isFiltersLoading}
          setShowShopByDisclosure={setShowShopByDisclosure}
          setShowLoginModal={setShowLoginModal}
          setLoginModalMode={setLoginModalMode}
          onClose={onClose}
        />
      </SideNavBarModal>
    </>
  );
};

export default NavBar;
