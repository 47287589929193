import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import LoginModalContext, {
  LoginModalContextState,
} from "@modules/user/LoginModalContext";
import {
  DynamicConfirmEmailModal,
  DynamicLoginModal,
  DynamicResetPasswordModal,
} from "@modules/user/components";
import useUser from "@modules/user/hooks/useUser";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { ConfirmEmailModalStep } from "@modules/user/components/ConfirmEmailModal";
import { LoginModalMode } from "@modules/user/types/LoginModalMode";
import { removeQueryParams } from "@modules/core/routing/routingHelpers";
import { Event as HotjarEvent, hotjar } from "@modules/core/hotjar/types";
import { useQueryClient } from "@tanstack/react-query";
import purchaseQueryKeys from "@modules/purchase/purchaseQueryKeys";

type Props = {
  onLogin?: () => void;
  isLoginModalOpen: boolean;
  onLoginModalClose: () => void;
  onEmailConfirmed?: () => void;
  children: ReactNode;
  loginModalMode: LoginModalMode;
  socialLoginParams?: string;
};

const LoginFlowWrapper = ({
  children,
  onLogin,
  isLoginModalOpen,
  onLoginModalClose,
  onEmailConfirmed,
  loginModalMode,
  socialLoginParams,
}: Props) => {
  const {
    user,
    isFetching: isUserFetching,
    isFetched: isUserFetched,
  } = useUser();
  const { t } = useTranslation(["purchase", "common"]);
  const [ignoreQueryChanges, setIgnoreQueryChanges] = useState(false);
  const router = useRouter();
  const { email, newEmail, token, confirm, social, externalToken, from } =
    router.query;
  const [showResetPasswordModal, setShowResetPasswordModal] =
    useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [confirmEmailEmail, setConfirmEmailEmail] = useState<string>("");
  const [confirmEmailToken, setConfirmEmailToken] = useState<string>("");
  const [showConfirmEmailModal, setShowConfirmEmailModal] =
    useState<boolean>(false);
  const [confirmEmailStep, setConfirmEmailStep] =
    useState<ConfirmEmailModalStep>("enterEmail");
  const [loginModalModeState, setLoginModalModeState] =
    useState<LoginModalMode>(loginModalMode);
  const [defaultEmailAddress, setDefaultEmailAddress] = useState<string>("");
  const [defaultFirstName, setDefaultFirstName] = useState<string>("");
  const [defaultLastName, setDefaultLastName] = useState<string>("");

  const loginModalContextState: LoginModalContextState =
    useMemo<LoginModalContextState>(
      () => ({
        setShowModal: setShowLoginModal,
        modeState: [loginModalModeState, setLoginModalModeState],
        setDefaultEmailAddress,
        setDefaultFirstName,
        setDefaultLastName,
      }),
      [loginModalModeState]
    );

  useEffect(() => {
    if (!ignoreQueryChanges) {
      if (email && token && confirm) {
        setShowLoginModal(false);
        setIgnoreQueryChanges(true);
      } else if (email && token) {
        setShowResetPasswordModal(true);
        setIgnoreQueryChanges(true);
      } else if (social === "needsEmailConfirmed" && externalToken) {
        // Show confirm email from social account modal
        if (email) {
          setConfirmEmailEmail(email.toString());
          setConfirmEmailStep("submitted");
        }
        setConfirmEmailToken(externalToken.toString());
        removeQueryParams(router, ["social", "email", "externalToken"]);
        setShowConfirmEmailModal(true);
        setIgnoreQueryChanges(true);
      } else if (from) {
        setIgnoreQueryChanges(true);

        if (user?.isLoggedIn) {
          removeQueryParams(router, ["from"]);
          if ((from as string).startsWith("/")) {
            router.push(`${process.env.NEXT_PUBLIC_HOST}${from as string}`);
          } else {
            router.push("/");
          }
        } else {
          setShowLoginModal(true);
        }
      }
    }
  }, [
    email,
    newEmail,
    token,
    confirm,
    social,
    externalToken,
    ignoreQueryChanges,
    router,
    t,
    from,
    user,
  ]);

  const queryClient = useQueryClient();

  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: purchaseQueryKeys.activeAftermarketPurchase(),
    });
  }, [queryClient]);

  const handleLoginModalClose = useCallback(
    (isLoggedIn: boolean) => {
      if (onLogin && isLoggedIn) {
        onLogin();
      }

      invalidateQueries();

      setShowLoginModal(false);
      setLoginModalModeState(loginModalMode);

      if (from && isLoggedIn) {
        removeQueryParams(router, ["from"]);
        if ((from as string).startsWith("/")) {
          router.push(`${process.env.NEXT_PUBLIC_HOST}${from as string}`);
        } else {
          router.push("/");
        }
      }

      onLoginModalClose();
    },
    [
      onLogin,
      invalidateQueries,
      loginModalMode,
      from,
      onLoginModalClose,
      router,
    ]
  );

  useEffect(() => {
    if (social === "success" && user && (!isUserFetching || isUserFetched)) {
      if (user.isLoggedIn) {
        handleLoginModalClose(true);
      }

      removeQueryParams(router, ["social"]);
    }
  }, [
    handleLoginModalClose,
    isUserFetched,
    isUserFetching,
    router,
    social,
    user,
  ]);

  useEffect(() => {
    if (isLoginModalOpen) {
      hotjar.event(HotjarEvent.UserLogin);
      setLoginModalModeState(loginModalModeState);
      setShowLoginModal(isLoginModalOpen);
    }
  }, [isLoginModalOpen, loginModalModeState]);

  const closeResetPasswordModal = (openLoginModal: boolean) => {
    setShowResetPasswordModal(false);
    setShowLoginModal(openLoginModal);
  };

  return (
    <LoginModalContext.Provider value={loginModalContextState}>
      {children}
      {showLoginModal && (
        <DynamicLoginModal
          modeState={[loginModalModeState, setLoginModalModeState]}
          showModal={showLoginModal}
          handleModalClose={handleLoginModalClose}
          email={defaultEmailAddress}
          firstName={defaultFirstName}
          lastName={defaultLastName}
          socialLoginParams={socialLoginParams}
        />
      )}
      {showResetPasswordModal && (
        <DynamicResetPasswordModal
          showModal={showResetPasswordModal}
          handleModalClose={closeResetPasswordModal}
        />
      )}
      {showConfirmEmailModal && (
        <DynamicConfirmEmailModal
          email={confirmEmailEmail}
          token={confirmEmailToken}
          step={confirmEmailStep}
          showModal={showConfirmEmailModal}
          handleModalClose={(wasSuccessful) => {
            if (wasSuccessful) {
              onEmailConfirmed?.();
            }
            setShowConfirmEmailModal(false);
          }}
          onEmailChange={(confirmEmail) => setConfirmEmailEmail(confirmEmail)}
          onStepChange={(confirmStep) => setConfirmEmailStep(confirmStep)}
          socialLoginParams={socialLoginParams}
        />
      )}
    </LoginModalContext.Provider>
  );
};

export default LoginFlowWrapper;
