import { CmsLayout } from "@modules/cms/types/CmsLayout";
import { PageSettingsLayout } from "@modules/cms/types/PageSettingsLayout";
import React, { ReactNode } from "react";

const getPageSettingsFromLayout = (cmsLayouts: CmsLayout[]) => {
  let result = null;

  const pageSettings = cmsLayouts.find(
    (layout) => layout?.typeName === "Page_Default_Flex_PageSettings"
  ) as unknown as PageSettingsLayout | null;

  if (pageSettings != null) {
    result = {
      hideHeader: pageSettings.hideHeader,
      hideFooter: pageSettings.hideFooter,
      showLogo: pageSettings.showLogo,
    };
  }

  return result;
};

export const getPageSettings = (children: ReactNode | ReactNode[]) => {
  let result = {
    hideHeader: false,
    hideFooter: false,
    showLogo: false,
  };

  if (React.isValidElement(children)) {
    const { cmsLayouts } = children.props;

    if (cmsLayouts) {
      const pageSettings = getPageSettingsFromLayout(cmsLayouts);

      if (pageSettings != null) {
        result = pageSettings;
      }
    }
  }

  return result;
};

export default {};
