import { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import classNames from "classnames";
import { DateTimeFormatter, LocalDateTime, ZoneOffset } from "@js-joda/core";
import Snackbar from "@common/components/Snackbar";
import { gtmPushData } from "@common/helpers/gtm";
import useLocalStorage from "@common/hooks/useLocalStorage";
import VehiclePopupDetails from "@modules/vehicle/types/VehiclePopupDetails";
import { Event as HotjarEvent, hotjar } from "@modules/core/hotjar/types";
import nextApi from "@modules/api/nextApi";
import Modal from "@common/components/Modal";
import CloudflareImagesImage from "@common/components/CloudflareImagesImage";
import useWindowDimensions from "@common/hooks/useWindowDimensions";
import { User } from "@modules/user/types/user";
import { VehicleDetail } from "@modules/vehicle/types/VehicleDetail";
import GravityFormsPopupModalEnum from "../constants/GravityFormsPopupModalEnum";
import GetBackgroundStyle from "../helpers/getBackgroundStyle";
import GravityForm from "./GravityForm";
import { GravityFormInput } from "../types/GravityFormLayout";

type Props = {
  origin: GravityFormsPopupModalEnum;
  user: User;
  vehicle?: VehicleDetail;
};

type LocalStorageDetail = {
  popupSeen: boolean;
  popupSeenDate: string | undefined;
  lastModifiedDate: string;
};

const defaultLocalStorageDetail: LocalStorageDetail = {
  popupSeen: false,
  popupSeenDate: undefined,
  lastModifiedDate: "",
};

const GravityFormsPopupModal = ({ origin, user, vehicle }: Props) => {
  const router = useRouter();
  const { t } = useTranslation(["common"]);
  const formatter = DateTimeFormatter.ofPattern("uuuu-MM-dd'T'HH:mm:ss");
  const { width } = useWindowDimensions();
  const isMobileView = width < 768;
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [popupDetails, setPopupDetails] = useState<VehiclePopupDetails>();
  const [vdpPopup, setVdpPopup] = useLocalStorage<LocalStorageDetail>(
    "vdpPopup",
    defaultLocalStorageDetail
  );
  const [vlpPopup, setVlpPopup] = useLocalStorage<LocalStorageDetail>(
    "vlpPopup",
    defaultLocalStorageDetail
  );
  const [allPagesPopup, setAllPagesPopup] = useLocalStorage<LocalStorageDetail>(
    "allPagesPopup",
    defaultLocalStorageDetail
  );

  const getPopupData = useCallback(
    async (pageOrigin: GravityFormsPopupModalEnum) => {
      const params = new URLSearchParams();
      params.append("language", router.locale?.toUpperCase() as string);
      params.append("origin", pageOrigin);

      await nextApi
        .get<VehiclePopupDetails>(
          `api/cms/getVehiclePopup?${params.toString()}`
        )
        .then((response) => {
          setPopupDetails(response);
          if (
            pageOrigin === "VDP" &&
            vdpPopup.lastModifiedDate !== response.modified
          ) {
            setVdpPopup({
              ...vdpPopup,
              popupSeen: false,
              lastModifiedDate: response.modified,
            });
          }
          if (
            pageOrigin === "VLP" &&
            vlpPopup.lastModifiedDate !== response.modified
          ) {
            setVlpPopup({
              ...vlpPopup,
              popupSeen: false,
              lastModifiedDate: response.modified,
            });
          }
          if (
            pageOrigin === "All" &&
            allPagesPopup.lastModifiedDate !== response.modified
          ) {
            setAllPagesPopup({
              ...allPagesPopup,
              popupSeen: false,
              lastModifiedDate: response.modified,
            });
          }
        })
        .catch(() => {
          return null;
        });
    },
    [
      router.locale,
      vdpPopup,
      vlpPopup,
      allPagesPopup,
      setVdpPopup,
      setVlpPopup,
      setAllPagesPopup,
    ]
  );

  const checkPopupExpiry = useCallback(
    (pageOrigin: GravityFormsPopupModalEnum) => {
      if (pageOrigin === GravityFormsPopupModalEnum.VDP) {
        if (!vdpPopup.popupSeenDate) return;
        const expiryDate = LocalDateTime.parse(
          vdpPopup.popupSeenDate,
          formatter
        );
        const hasVdpExpired = LocalDateTime.now(ZoneOffset.UTC)
          .minusDays(30)
          .isAfter(expiryDate);
        if (hasVdpExpired) {
          setVdpPopup((prevVdpPopup) => {
            return {
              ...prevVdpPopup,
              popupSeen: false,
              popupSeenDate: undefined,
            };
          });
        }
      }
      if (pageOrigin === GravityFormsPopupModalEnum.VLP) {
        if (!vlpPopup.popupSeenDate) return;
        const expiryDate = LocalDateTime.parse(
          vlpPopup.popupSeenDate,
          formatter
        );
        const hasVlpExpired = LocalDateTime.now(ZoneOffset.UTC)
          .minusDays(30)
          .isAfter(expiryDate);
        if (hasVlpExpired) {
          setVlpPopup((prevVlpPopup) => {
            return {
              ...prevVlpPopup,
              popupSeen: false,
              popupSeenDate: undefined,
            };
          });
        }
      }
      if (pageOrigin === GravityFormsPopupModalEnum.All) {
        if (!allPagesPopup.popupSeenDate) return;
        const expiryDate = LocalDateTime.parse(
          allPagesPopup.popupSeenDate,
          formatter
        );
        const hasAllPagesExpired = LocalDateTime.now(ZoneOffset.UTC)
          .minusDays(30)
          .isAfter(expiryDate);
        if (hasAllPagesExpired) {
          setAllPagesPopup((prevAllPagesPopup) => {
            return {
              ...prevAllPagesPopup,
              popupSeen: false,
              popupSeenDate: undefined,
            };
          });
        }
      }
    },
    [
      vdpPopup,
      vlpPopup,
      allPagesPopup,
      setVdpPopup,
      setVlpPopup,
      setAllPagesPopup,
      formatter,
    ]
  );

  const shouldFetchPopupData = useCallback(() => {
    const pathsToHideAllPagesPopup: RegExp[] = [
      /^\/contracts\/billofsale(\/.*)?$/,
      /^\/credit$/,
      /^\/cars(\/.*)?$/,
      /^\/car(\/.*)?$/,
    ];
    switch (origin) {
      case GravityFormsPopupModalEnum.VDP:
        return !vdpPopup.popupSeen;
      case GravityFormsPopupModalEnum.VLP:
        return !vlpPopup.popupSeen;
      case GravityFormsPopupModalEnum.All:
        return (
          !pathsToHideAllPagesPopup.some((path) =>
            path.test(router.pathname)
          ) && !allPagesPopup.popupSeen
        );
      default:
        return false;
    }
  }, [
    origin,
    vdpPopup.popupSeen,
    vlpPopup.popupSeen,
    allPagesPopup.popupSeen,
    router.pathname,
  ]);

  // fetch Popup content from WP and track the version of popup content
  useEffect(() => {
    if (!popupDetails && shouldFetchPopupData()) {
      getPopupData(origin);
      checkPopupExpiry(origin);
    }
    // Ensure that the popup is not fetched multiple times, and only fetched when component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // VDP only. Show popup when user scrolls to a certain position
  useEffect(() => {
    if (
      origin === GravityFormsPopupModalEnum.VDP &&
      !vdpPopup.popupSeen &&
      popupDetails
    ) {
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const triggerPointMin = 1500;
        const triggerPointMax = 1600;

        if (
          scrollPosition >= triggerPointMin &&
          scrollPosition <= triggerPointMax
        ) {
          setIsPopupOpen(true);
          setVdpPopup((prevVdpPopup) => {
            return {
              popupSeen: true,
              popupSeenDate: formatter.format(
                LocalDateTime.now(ZoneOffset.UTC)
              ),
              lastModifiedDate: prevVdpPopup.lastModifiedDate,
            };
          });
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
    return undefined;
  }, [setIsPopupOpen, popupDetails, vdpPopup, setVdpPopup, formatter, origin]);

  // VLP and All Pages. Show popup when user lands on the page
  useEffect(() => {
    if (
      origin === GravityFormsPopupModalEnum.VLP &&
      !vlpPopup.popupSeen &&
      popupDetails
    ) {
      setIsPopupOpen(true);
      setVlpPopup((prevVlpPopup) => {
        return {
          ...prevVlpPopup,
          popupSeen: true,
          popupSeenDate: formatter.format(LocalDateTime.now(ZoneOffset.UTC)),
        };
      });
    }
    if (
      origin === GravityFormsPopupModalEnum.All &&
      !allPagesPopup.popupSeen &&
      popupDetails
    ) {
      setIsPopupOpen(true);
      setAllPagesPopup((prevAllPagesPopup) => {
        return {
          ...prevAllPagesPopup,
          popupSeen: true,
          popupSeenDate: formatter.format(LocalDateTime.now(ZoneOffset.UTC)),
        };
      });
    }
    return undefined;
  }, [
    setIsPopupOpen,
    popupDetails,
    vlpPopup,
    setVlpPopup,
    allPagesPopup,
    setAllPagesPopup,
    formatter,
    origin,
  ]);

  const handleFormSubmit = (formInput: GravityFormInput) => {
    setIsPopupOpen(false);
    Snackbar.Pop({
      message:
        (popupDetails &&
          popupDetails.formSide.gravityForm.form.confirmations?.[0]?.message) ||
        t("thanks_for_applying"),
    });
    if (origin === GravityFormsPopupModalEnum.VDP) {
      gtmPushData({
        event: "VDP Popup Modal",
        element: "Submit",
        descriptor: "VDP Popup Modal Submitted",
        user_id: user.id,
        firstName: formInput.firstName,
        lastName: formInput.lastName,
        email: formInput.email,
        phone: formInput.phoneNumber,
      });
      hotjar.event(HotjarEvent.VdpPopupModalSubmit);
    }
    if (origin === GravityFormsPopupModalEnum.VLP) {
      gtmPushData({
        event: "VLP Popup Modal",
        element: "Submit",
        descriptor: "VLP Popup Modal Submitted",
        user_id: user.id,
        firstName: formInput.firstName,
        lastName: formInput.lastName,
        email: formInput.email,
        phone: formInput.phoneNumber,
      });
      hotjar.event(HotjarEvent.VlpPopupModalSubmit);
    }
    if (origin === GravityFormsPopupModalEnum.All) {
      gtmPushData({
        event: "All Pages Popup Modal",
        element: "Submit",
        descriptor: "All Pages Popup Modal Submitted",
        user_id: user.id,
        firstName: formInput.firstName,
        lastName: formInput.lastName,
        email: formInput.email,
        phone: formInput.phoneNumber,
      });
      hotjar.event(HotjarEvent.AllPagesPopupModalSubmit);
    }
  };

  const handlePopupClose = () => {
    if (origin === GravityFormsPopupModalEnum.VDP) {
      gtmPushData({
        event: "VDP Popup Modal",
        element: "Close",
        descriptor: "VDP Popup Modal Rejected/Closed",
        user_id: user?.id,
      });
      hotjar.event(HotjarEvent.VdpPopupModalClose);
    }
    if (origin === GravityFormsPopupModalEnum.VLP) {
      gtmPushData({
        event: "VLP Popup Modal",
        element: "Close",
        descriptor: "VLP Popup Modal Rejected/Closed",
        user_id: user?.id,
      });
      hotjar.event(HotjarEvent.VlpPopupModalClose);
    }
    if (origin === GravityFormsPopupModalEnum.All) {
      gtmPushData({
        event: "All Pages Popup Modal",
        element: "Close",
        descriptor: "All Pages Popup Modal Rejected/Closed",
        user_id: user?.id,
      });
      hotjar.event(HotjarEvent.AllPagesPopupModalClose);
    }
  };

  return (
    <Modal
      isOpen={isPopupOpen}
      onClose={handlePopupClose}
      isPopup
      isPopupImageTop={popupDetails?.imageSide.imagePosition === "Top"}
    >
      {popupDetails && (
        <div
          className={classNames(
            `flex flex-col-reverse rounded-lg overflow-hidden`,
            {
              "md:flex-row": popupDetails.imageSide.imagePosition === "Right",
              "md:flex-row-reverse":
                popupDetails.imageSide.imagePosition === "Left",
              "md:flex-col-reverse":
                popupDetails.imageSide.imagePosition === "Top",
            }
          )}
        >
          <div
            className={classNames(`w-full`, {
              "md:w-1/2": popupDetails.imageSide.imagePosition !== "Top",
            })}
            style={GetBackgroundStyle(
              popupDetails.formSide.gravityForm.background
            )}
          >
            {(popupDetails.formSide.heading.isActive ||
              popupDetails.formSide.subheading.isActive) && (
              <div className="p-4 text-center">
                {popupDetails.formSide.heading.isActive && (
                  <h3
                    className={classNames("text-primary-deep leading-snug", {
                      "mb-4": popupDetails.formSide.subheading.isActive,
                    })}
                  >
                    {popupDetails.formSide.heading.label}
                  </h3>
                )}
                {popupDetails.formSide.subheading.isActive && (
                  <p className="text-base text-gray-500">
                    {popupDetails.formSide.subheading.label}
                  </p>
                )}
              </div>
            )}
            <div className="w-full text-center space-y-3">
              <GravityForm
                form={popupDetails.formSide.gravityForm.form}
                formId={popupDetails.formSide.gravityForm.id}
                campaignTag={popupDetails.formSide.gravityForm.campaignTag}
                vinsolutionsId={
                  popupDetails.formSide.gravityForm.vinsolutionsId
                }
                confirmationText={
                  popupDetails.formSide.gravityForm.form.confirmations?.[0]
                    ?.message
                }
                submitButtonText={
                  popupDetails.formSide.gravityForm.form.submitButton?.text
                }
                onSubmit={handleFormSubmit}
                vehicle={vehicle}
                isPopup
              />
            </div>
          </div>
          <div
            className={classNames(
              `w-full flex-grow flex items-center justify-center`,
              {
                "md:w-1/2": popupDetails.imageSide.imagePosition !== "Top",
              }
            )}
            style={{
              backgroundColor:
                popupDetails.imageSide.backgroundColor ?? "transparent",
            }}
          >
            {isMobileView ? (
              <CloudflareImagesImage
                src={popupDetails.imageSide.mobileImage.sourceUrl ?? ""}
                alt={popupDetails.imageSide.mobileImage.altText ?? ""}
                width="300"
                height="300"
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <CloudflareImagesImage
                src={popupDetails.imageSide.desktopImage.sourceUrl ?? ""}
                alt={popupDetails.imageSide.desktopImage.altText ?? ""}
                width="500"
                height="500"
                style={{ width: "100%", height: "100%" }}
              />
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default GravityFormsPopupModal;
