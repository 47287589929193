import { useTranslation } from "next-i18next";

export type navLink = {
  url: string;
  title: string;
};

const GetNavItems = (
  navType: "Top" | "TopSubNav" | "Side" | "SideSubNav"
): navLink[] => {
  const { t } = useTranslation(["common"]);
  let result: navLink[] = [];
  switch (navType) {
    case "Top":
      result = [
        { url: "/financing", title: t("common:financing") },
        { url: "/free-credit-check", title: t("common:credit_check") },
        { url: "/trade", title: t("common:trade_in") },
        { url: "/how-it-works", title: t("common:how_it_works") },
        { url: "/coverages", title: t("common:coverages") },
      ];
      break;
    case "TopSubNav":
    case "SideSubNav":
      result = [
        { url: "/locations", title: t("common:locations") },
        { url: "/blog", title: t("common:blog") },
        { url: "/about", title: t("common:about_us") },
      ];
      break;
    case "Side":
      result = [
        { url: "/financing", title: t("common:financing") },
        { url: "/free-credit-check", title: t("common:credit_check") },
        { url: "/trade", title: t("common:trade_in") },
        { url: "/how-it-works", title: t("common:how_it_works") },
        { url: "/coverages", title: t("common:coverages") },
      ];
      break;
    default:
      throw Error("Nav type not supported");
  }
  return result;
};

export default GetNavItems;
