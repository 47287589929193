import { useQuery } from "@tanstack/react-query";
import nextApi from "@modules/api/nextApi";
import PromoBannerDetails from "../types/PromoBannerDetails";

const getPromoBanner = async (
  isPreview: boolean,
  language: string
): Promise<PromoBannerDetails | undefined> => {
  const params = new URLSearchParams();
  params.append("isPreview", isPreview ? "1" : "0");
  params.append("language", language);

  return nextApi
    .get<PromoBannerDetails>(`api/cms/getPromotionBanner?${params.toString()}`)
    .catch(() => undefined);
};

export default function usePromotionsBanner(
  isPreview: boolean,
  language: string
) {
  const { data, error, isFetchedAfterMount } = useQuery({
    queryKey: ["getPromoBanner"],
    queryFn: () => getPromoBanner(isPreview, language),
    // 30 minutes
    staleTime: 1_800_000,
  });

  return {
    data,
    error,
    isFetchedAfterMount,
  };
}
