import { useReducer } from "react";
import {
  FilterAction,
  FilterActions,
  FilterKeys,
  StateWithId,
  VehicleFilter,
  VehicleFilters,
  VehicleMakeFilterState,
  VehicleModelFilterState,
  VehicleSearchFilter,
  VehicleYearFilterState,
} from "@modules/vehicle/types/vehicleFilter";

const reducer = (
  vehicleFilters: VehicleFilters,
  action: FilterAction
): VehicleFilters => {
  switch (action.type) {
    case FilterActions.AddFuelEfficiencyFilter: {
      return {
        filters: [
          ...vehicleFilters.filters.filter(
            (f) => f.key !== FilterKeys.FuelEfficiency
          ),
          { key: FilterKeys.FuelEfficiency, state: action.payload },
        ],
        isDirty: true,
      };
    }
    case FilterActions.AddYearFilter: {
      return {
        filters: [
          ...vehicleFilters.filters.filter((f) => f.key !== FilterKeys.Year),
          { key: FilterKeys.Year, state: action.payload },
        ],
        isDirty: true,
      };
    }
    case FilterActions.AddMileageFilter: {
      return {
        filters: [
          ...vehicleFilters.filters.filter((f) => f.key !== FilterKeys.Mileage),
          { key: FilterKeys.Mileage, state: action.payload },
        ],
        isDirty: true,
      };
    }
    case FilterActions.AddMakeFilter: {
      // Remove existing model filters for this make
      const toRemove = (vf: VehicleFilter) =>
        vf.key === FilterKeys.Model && vf.state.make === action.payload.make;

      if (
        vehicleFilters.filters.find(
          (vf) =>
            vf.key === FilterKeys.Make && vf.state.make === action.payload.make
        )
      ) {
        return vehicleFilters;
      }

      return {
        filters: [
          ...vehicleFilters.filters.filter((vf) => !toRemove(vf)),
          { key: FilterKeys.Make, state: action.payload },
        ],
        isDirty: true,
      };
    }
    case FilterActions.AddModelFilter: {
      // Remove existing make filter
      const toRemove = (vf: VehicleFilter) =>
        vf.key === FilterKeys.Make && vf.state.make === action.payload.make;

      if (
        vehicleFilters.filters.find(
          (vf) =>
            vf.key === FilterKeys.Model && vf.state.id === action.payload.id
        )
      ) {
        return vehicleFilters;
      }

      return {
        filters: [
          ...vehicleFilters.filters.filter((vf) => !toRemove(vf)),
          { key: FilterKeys.Model, state: action.payload },
        ],
        isDirty: true,
      };
    }
    case FilterActions.AddFeatureFilter: {
      return {
        filters: [
          ...vehicleFilters.filters,
          { key: FilterKeys.KeyFeatures, state: action.payload },
        ],
        isDirty: true,
      };
    }
    case FilterActions.AddTransmissionFilter: {
      return {
        filters: [
          ...vehicleFilters.filters,
          { key: FilterKeys.Transmission, state: action.payload },
        ],
        isDirty: true,
      };
    }
    case FilterActions.AddBodyStyleFilter: {
      return {
        filters: [
          ...vehicleFilters.filters,
          { key: FilterKeys.BodyStyle, state: action.payload },
        ],
        isDirty: true,
      };
    }
    case FilterActions.AddExteriorColourFilter: {
      return {
        filters: [
          ...vehicleFilters.filters,
          { key: FilterKeys.ExteriorColour, state: action.payload },
        ],
        isDirty: true,
      };
    }
    case FilterActions.AddFuelTypeFilter: {
      return {
        filters: [
          ...vehicleFilters.filters,
          { key: FilterKeys.FuelType, state: action.payload },
        ],
        isDirty: true,
      };
    }
    case FilterActions.AddSortByFilter: {
      return {
        filters: [
          ...vehicleFilters.filters.filter((f) => f.key !== FilterKeys.SortBy),
          { key: FilterKeys.SortBy, state: action.payload },
        ],
        isDirty: true,
      };
    }
    case FilterActions.AddPaymentFilter: {
      // If just a payment factor change -> don't clear Price filter
      const isPaymentFactorChange =
        action.payload.maxPayment == null && action.payload.minPayment == null;

      const predicate = (f: VehicleFilter) =>
        f.key !== FilterKeys.Payment &&
        (isPaymentFactorChange || f.key !== FilterKeys.Price);

      return {
        filters: [
          ...vehicleFilters.filters.filter(predicate),
          { key: FilterKeys.Payment, state: action.payload },
        ],
        isDirty: true,
      };
    }
    case FilterActions.AddPriceFilter: {
      return {
        filters: [
          ...vehicleFilters.filters.filter(
            (f) => f.key !== FilterKeys.Payment && f.key !== FilterKeys.Price
          ),
          { key: FilterKeys.Price, state: action.payload },
        ],
        isDirty: true,
      };
    }
    case FilterActions.AddLocationFilter: {
      // Remove existing province filter
      const toRemove = (vf: VehicleFilter) =>
        vf.key === FilterKeys.Province &&
        vf.state.id === action.payload.provinceId.toString();

      if (
        vehicleFilters.filters.find(
          (vf) =>
            vf.key === FilterKeys.Location && vf.state.id === action.payload.id
        )
      ) {
        return vehicleFilters;
      }

      return {
        filters: [
          ...vehicleFilters.filters.filter((vf) => !toRemove(vf)),
          { key: FilterKeys.Location, state: action.payload },
        ],
        isDirty: true,
      };
    }
    case FilterActions.AddProvinceFilter: {
      // Remove existing location filters for this province
      const toRemove = (vf: VehicleFilter) =>
        vf.key === FilterKeys.Location &&
        vf.state.provinceId.toString() === action.payload.id;

      if (
        vehicleFilters.filters.find(
          (vf) =>
            vf.key === FilterKeys.Province && vf.state.id === action.payload.id
        )
      ) {
        return vehicleFilters;
      }

      return {
        filters: [
          ...vehicleFilters.filters.filter((vf) => !toRemove(vf)),
          { key: FilterKeys.Province, state: action.payload },
        ],
        isDirty: true,
      };
    }
    case FilterActions.RemoveFilter: {
      return {
        filters: vehicleFilters.filters.filter(
          (vf) => vf.key !== action.payload
        ),
        isDirty: true,
      };
    }
    case FilterActions.RemoveMakeFilter: {
      const toRemove = (vf: VehicleFilter) =>
        vf.key === FilterKeys.Make && vf.state.make === action.payload.make;

      return {
        filters: vehicleFilters.filters.filter((vf) => !toRemove(vf)),
        isDirty: true,
      };
    }
    case FilterActions.RemoveByIdAndType: {
      const toRemove = (vf: VehicleFilter) =>
        vf.key === action.payload.type &&
        (vf.state as StateWithId).id === action.payload.id;

      return {
        filters: vehicleFilters.filters.filter((vf) => !toRemove(vf)),
        isDirty: true,
      };
    }
    case FilterActions.ResetFilters: {
      return { filters: [], isDirty: true };
    }
    case FilterActions.SetInitialFilters: {
      return { filters: action.payload, isDirty: true };
    }
    case FilterActions.SetSearchFilters: {
      let newFilters: VehicleFilter[] = [];
      const previousSearch = vehicleFilters.filters.find(
        (x) => x.key === FilterKeys.Search
      ) as VehicleSearchFilter;
      const currentSearch = action.payload;

      if (
        previousSearch &&
        currentSearch &&
        previousSearch.originalString === currentSearch.originalString
      ) {
        newFilters = vehicleFilters.filters;
      } else {
        newFilters.push(currentSearch);
      }

      return {
        filters: newFilters,
        isDirty: true,
      };
    }
    case FilterActions.SetSavedVehiclesFilter: {
      if (action.payload) {
        return {
          filters: [{ key: FilterKeys.SavedVehicles, state: action.payload }],
          isDirty: true,
        };
      }

      return {
        filters: vehicleFilters.filters.filter(
          (f) => f.key !== FilterKeys.SavedVehicles
        ),
        isDirty: true,
      };
    }
    case FilterActions.AddWarrantyFilter: {
      return {
        filters: [
          ...vehicleFilters.filters,
          { key: FilterKeys.Warranty, state: action.payload },
        ],
        isDirty: true,
      };
    }
    case FilterActions.RemoveWarrantyFilter: {
      return {
        filters: vehicleFilters.filters.filter(
          (filter) => filter.key !== FilterKeys.Warranty
        ),
        isDirty: true,
      };
    }
    case FilterActions.SetFiltersFromSearch: {
      const yearFilter = action.payload.find(
        (currentFilter) => currentFilter.key === FilterKeys.Year
      )?.state as VehicleYearFilterState | undefined;
      const makeFilters = action.payload
        .filter((currentFilter) => currentFilter.key === FilterKeys.Make)
        .map((currentFilter) => currentFilter.state as VehicleMakeFilterState);
      const modelFilters = action.payload
        .filter((currentFilter) => currentFilter.key === FilterKeys.Model)
        .map((currentFilter) => currentFilter.state as VehicleModelFilterState);

      let filters = vehicleFilters;

      if (yearFilter) {
        filters = reducer(filters, {
          type: FilterActions.AddYearFilter,
          payload: yearFilter,
        });
      }

      makeFilters.forEach((currentMakeFilter) => {
        filters = reducer(filters, {
          type: FilterActions.AddMakeFilter,
          payload: currentMakeFilter,
        });
      });

      modelFilters.forEach((currentModelFilter) => {
        filters = reducer(filters, {
          type: FilterActions.AddModelFilter,
          payload: currentModelFilter,
        });
      });

      const searchFilter = action.payload.find(
        (currentFilter) => currentFilter.key === FilterKeys.Search
      );

      if (
        searchFilter &&
        !filters.filters.find(
          (currentFilter) =>
            currentFilter.key === FilterKeys.Search &&
            currentFilter.state === searchFilter.state
        )
      ) {
        filters = {
          filters: [
            ...filters.filters.filter(
              (currentFilter) => currentFilter.key !== FilterKeys.Search
            ),
            searchFilter,
          ],
          isDirty: filters.isDirty,
        };
      }

      return filters;
    }
    case FilterActions.RemoveSearchFilter:
      return {
        filters: vehicleFilters.filters.filter(
          (f) => f.key !== FilterKeys.Search
        ),
        isDirty: true,
      };
    case FilterActions.SetVehicleFiltersOnly: {
      let newFilters = action.payload;
      const existingSearch = vehicleFilters.filters.find(
        (x) => x.key === FilterKeys.Search
      ) as VehicleSearchFilter;
      const currentSearch = newFilters.find((x) => x.key === FilterKeys.Search);

      if (existingSearch && currentSearch) {
        newFilters = [
          ...action.payload.filter((s) => s.key !== FilterKeys.Search),
          {
            key: FilterKeys.Search,
            state: currentSearch.state,
            originalString: existingSearch
              ? existingSearch.originalString
              : undefined,
          } as VehicleSearchFilter,
        ];
      }

      return {
        filters: newFilters,
        isDirty: false,
      };
    }
    case FilterActions.AddPassengerCapacityFilter: {
      return {
        filters: [
          ...vehicleFilters.filters,
          { key: FilterKeys.PassengerCapacity, state: action.payload },
        ],
        isDirty: true,
      };
    }
    default:
  }

  return vehicleFilters;
};

export default function useFilterReducer() {
  return useReducer(reducer, { filters: [], isDirty: true });
}
