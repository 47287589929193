import PageScriptValidator from "./PageScriptValidator";

const pathsToHideChat: RegExp[] = [
  /^\/contracts\/billofsale(\/.*)?$/,
  /^\/credit$/,
  /^\/profile(?:\/[^/?]*)*/,
  /^\/trade(?:\/[^/?]*)*/,
];

const scriptSrc = "https://cdn.gubagoo.io/toolbars/122230/loader_122230_1.js";

const setElementStyles = (isShown: boolean) => {
  const chatBubble = document.querySelector(".gg-app[data-app='chat']");
  const toolbar = document.querySelector(".gg-toolbar");

  if (chatBubble instanceof HTMLElement) {
    chatBubble.style.display = isShown ? "block" : "none";
    chatBubble.style.zIndex = "40";
  }

  if (toolbar instanceof HTMLElement) {
    toolbar.style.display = isShown ? "block" : "none";
    toolbar.style.zIndex = "40";
  }
};

const Gubagoo = () => {
  return (
    <PageScriptValidator
      pathsToHideScript={pathsToHideChat}
      scriptSrc={scriptSrc}
      async
      setElementStyles={setElementStyles}
      strategy="lazyOnload"
    />
  );
};

export default Gubagoo;
